import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Avatar,
  Box,
  Container,
  Flex,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import resume from "../components/files/Salman Zaheer Resume.pdf";

gsap.registerPlugin(ScrollTrigger);

const Resume = () => {
  useGSAP(() => {
    // progress bar animation
    gsap.to("#progressBar1", {
      height: "100%",
      backgroundColor: "#B9010E",
      duration: 0.5,
      scrollTrigger: {
        trigger: "#avatar1",

        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.to("#progressBar2", {
      height: "100%",
      backgroundColor: "#B9010E",
      duration: 0.5,
      scrollTrigger: {
        trigger: "#avatar2",

        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.to("#progressBar3", {
      height: "100%",
      backgroundColor: "#B9010E",
      duration: 0.5,
      scrollTrigger: {
        trigger: "#avatar3",

        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });

    // Date animation
    gsap.to("#date1", {
      opacity: 1,
      duration: 0.5,
      scrollTrigger: {
        trigger: "#avatar1",

        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.to("#date2", {
      opacity: 1,
      duration: 0.5,
      scrollTrigger: {
        trigger: "#avatar2",

        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.to("#date3", {
      opacity: 1,
      duration: 0.5,
      scrollTrigger: {
        trigger: "#avatar3",

        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });

    // Avatar color animation
    gsap.to("#avatar1", {
      backgroundColor: "#B9010E",
      zIndex: 1,
      scrollTrigger: {
        trigger: "#avatar1",

        start: "top 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.to("#avatar2", {
      backgroundColor: "#B9010E",
      zIndex: 1,
      scrollTrigger: {
        trigger: "#avatar2",

        start: "top 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.to("#avatar3", {
      backgroundColor: "#B9010E",
      zIndex: 1,
      scrollTrigger: {
        trigger: "#avatar3",

        start: "top 60%",
        end: "top 30%",
        scrub: 1,
      },
    });

    // Scroll down text animation
    const tl = gsap.timeline();
    tl.fromTo(
      "#scrollDownText",
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 1,
        ease: "power1.out",
        scrollTrigger: {
          trigger: "#scrollDownText",
          start: "top 75%",
          end: "top 50%",
          scrub: 1,
        },
      }
    );
    /* Card animation */
    gsap.from("#cardLeft1", {
      x: -300,
      opacity: 0,
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: "#avatar1",
        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.from("#cardRight1", {
      x: +300,
      opacity: 0,
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: "#avatar2",
        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
    gsap.from("#cardLeft2", {
      x: -300,
      opacity: 0,
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: "#avatar3",
        start: "bottom 60%",
        end: "top 30%",
        scrub: 1,
      },
    });
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      bg="brand.100"
    >
      <Container width="600px" height="25vh" />
      <Heading as="h1" fontSize="9xl" color="brand.200" mb={4}>
        Work Experience
      </Heading>
      <Container width="600px" height="25vh" />
      <Box id="scrollDownText">
        <Text>Scroll down</Text>
        <ChevronDownIcon />
      </Box>
      <Container width="600px" height="15vh" />
      {/* WORK TIMELINE */}
      <VStack width="90%" spacing={0}>
        {/* 1 */}
        <HStack spacing={2} justify="center">
          <Box width="300px" height="200px">
            {/* CARD */}
            <Box
              id="cardLeft1"
              width="100%"
              height="100%"
              bg="purple.50"
              opacity={1}
              p={2}
              boxShadow="sm"
              borderRadius="lg"
            >
              <Flex direction="column">
                <AbsoluteCenter>
                  <Text fontSize="3xl" fontWeight="bold" color="brand.200">
                    Chesapeak Plywood
                  </Text>
                  <br />
                  <Text fontWeight="bold">IT & Network Engineer (intern)</Text>
                </AbsoluteCenter>
              </Flex>
            </Box>
          </Box>
          {/* TIMELINE */}
          <Flex direction="column" width="100px" height="200px" align="center">
            <Avatar
              id="avatar1"
              bg="gray.600"
              icon={<ChevronLeftIcon />}
              zIndex="100"
            />
            <Box id="backLine" width="5px" height="100%" bgColor="gray.600">
              {/* PROGRESS BAR */}
              <Box id="progressBar1" height="0" width="5px" />
            </Box>
          </Flex>
          <Box width="300px" height="200px">
            {/* DATE */}
            <Box
              display="flex"
              width="100%"
              height="100%"
              p={2}
              alignItems="center"
            >
              <Text id="date1" fontSize="xl" fontWeight="bold" opacity={0}>
                Jun 2022 - Aug 2022
              </Text>
            </Box>
          </Box>
        </HStack>
        {/* 2 */}
        <HStack spacing={2} justify="center">
          <Box width="300px" height="200px">
            <Box
              display="flex"
              width="100%"
              height="100%"
              p={2}
              alignItems="center"
            >
              <Text id="date2" fontSize="xl" fontWeight="bold" opacity={0}>
                Aug 2019 - Jan 2024
              </Text>
            </Box>
          </Box>
          {/* TIMELINE */}
          <Flex direction="column" width="100px" height="200px" align="center">
            <Avatar
              id="avatar2"
              bg="gray.600"
              icon={<ChevronRightIcon />}
              zIndex="100"
            />
            <Box id="backLine" width="5px" height="100%" bgColor="gray.600">
              {/* PROGRESS BAR */}
              <Box id="progressBar2" height="0%" width="5px" />
            </Box>
          </Flex>
          <Box width="300px" height="200px">
            {/* CARD */}
            <Box
              id="cardRight1"
              width="100%"
              height="100%"
              bg="purple.50"
              opacity={1}
              p={2}
              boxShadow="sm"
              borderRadius="lg"
            >
              <Flex direction="column">
                <AbsoluteCenter>
                  <Text fontSize="3xl" fontWeight="bold" color="brand.200">
                    Grapes & Grains
                  </Text>
                  <br />
                  <Text fontWeight="bold">Store manager</Text>
                </AbsoluteCenter>
              </Flex>
            </Box>
          </Box>
        </HStack>
        {/* 3 */}
        <HStack spacing={2} justify="center">
          <Box width="300px" height="200px">
            {/* CARD */}
            <Box
              id="cardLeft2"
              width="100%"
              height="100%"
              bg="purple.50"
              opacity={1}
              p={2}
              boxShadow="sm"
              borderRadius="lg"
            >
              <Flex direction="column">
                <AbsoluteCenter axis="vertical">
                  <Text fontSize="3xl" fontWeight="bold" color="brand.200">
                    Ciena
                  </Text>
                  <br />
                  <Text fontWeight="bold">
                    Solutions Engineering & Introduction Engineer
                  </Text>
                </AbsoluteCenter>
              </Flex>
            </Box>
          </Box>
          {/* TIMELINE */}
          <Flex direction="column" width="100px" height="200px" align="center">
            <Avatar
              id="avatar3"
              bg="gray.600"
              icon={<ChevronLeftIcon />}
              zIndex="100"
            />
            <Box id="backLine" width="5px" height="100%" bgColor="gray.600">
              {/* PROGRESS BAR */}
              <Box id="progressBar3" height="0%" width="5px" />
            </Box>
          </Flex>
          <Box width="300px" height="200px">
            <Box
              display="flex"
              width="100%"
              height="100%"
              p={2}
              alignItems="center"
            >
              <Text id="date3" fontSize="xl" fontWeight="bold" opacity={0}>
                Jan 2024 - Present
              </Text>
            </Box>
          </Box>
        </HStack>
      </VStack>
      {/**TEMP */}
      <Container width="600px" height="40vh" />
      <Box>
        <Text fontSize="lg">
          Need more details?{" "}
          <Link
            href={resume}
            target="_blank"
            margin="0 1rem"
            color="brand.400"
            fontSize="xl"
            fontWeight="bold"
            _hover={{ textDecoration: "none", color: "brand.200" }}
          >
            Download PDF here
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default Resume;
