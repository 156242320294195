import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#F1F0F2",
        color: "#104729",
      },
    },
  },
  colors: {
    brand: {
      100: "#E3E1E5", // Background
      200: "#B9010E", // Primary
      300: "#C7C3CC", // Secondary
      400: "#140729", // Text
    },
  },
  fonts: {
    heading: `'Quantico', 'sans-serif'`,
    body: `'Lato', 'sans-serif'`,
  },
});

export default theme;
