import { Box, Flex, Text, Input, Button, Heading } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box
      bg="brand.400"
      p={4}
      height="150px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Heading fontSize="6xl" color="brand.200" mt={10}>
        Salman Zaheer
      </Heading>
    </Box>
  );
};

export default Footer;
