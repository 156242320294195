import { Box, Container, Input, InputGroup, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

// List of programming jokes
const jokes = [
  "Why do programmers prefer dark mode? Because light attracts bugs.",
  "How many programmers does it take to change a light bulb? None, that's a hardware problem.",
  "Why do Java developers wear glasses? Because they can't C#.",
  "What do you call a programmer from Finland? Nerdic.",
  "Why do programmers hate nature? It has too many bugs.",
  "How do you comfort a JavaScript bug? You console it.",
  "Why was the JavaScript developer sad? Because he didn't know how to 'null' his feelings.",
  "What's a programmer's favorite hangout place? Foo Bar.",
  "How does a computer get drunk? It takes screenshots.",
  "Why was the developer bankrupt? Because he used up all his cache.",
  "Why did the programmer quit his job? Because he didn't get arrays!",
  "Why do programmers always mix up Halloween and Christmas? Because Oct 31 == Dec 25!",
  "There are 10 types of people in the world: Those who understand binary, and those who don't.",
  "Why did the function break up with the variable? Because it had too many arguments.",
  "What's the object-oriented way to become wealthy? Inheritance.",
  "Why did the database administrator leave his wife? She had one-to-many relationships.",
  "Why did the programmer refuse to go to the gym? He said he couldn't handle the loops.",
  "A SQL query walks into a bar, walks up to two tables and asks, 'Can I join you?'",
  "How do you comfort a JavaScript bug? You console it.",
  "What's the best part about TCP jokes? I get to keep telling them until you get them.",
];

const About = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [isActiveTerminal, setIsActiveTerminal] = useState(true);
  const inputRef = useRef();
  const containerRef = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [output]);

  const handleClickInside = () => {
    setIsActiveTerminal(true);
    inputRef.current.focus();
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsActiveTerminal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function printHelp() {
    return `
This is totally not a real terminal.
Version 0.5.2-pre. 
These shell commands are defined internally.
--------------------------------------------------------------
| Command       |  Description                               |
|---------------|--------------------------------------------|
| ls            | List files                                 |
| cat           | cat [filename] Output file data            |
| cd            | cd [dir] Change working directory          |
| github        | Redirect to my Github repo                 |
| linkedin      | Redirect to my LinkedIn profile            |
| help          | Show available commands                    |
| joke          | Tell a joke                                |
| reboot        | restart shell process                      |
| time          | What time is it?                           |
| whois         | Information about Salman                   |
--------------------------------------------------------------`;
  }

  function printLs() {
    return `
projects.txt    cats-i-like.txt    upcoming-features.txt    salman.txt       
`;
  }

  function printInvalid() {
    return `
Invalid command. Type "help" to see the commands list.
    `;
  }

  function printWhois() {
    return `
    Salman Zaheer introduces himself as a Network Solutions Engineer, Software Developer, UI/UX designer, 
    and an enthusiastic tech aficionado. He enjoys building technological solutions and finding creative 
    ways to solve problems.

    Currently, Salman is employed at Ciena as a Solutions Engineer, where he handles vBNG technology 
    solutions for clients. His work routinely involves VMs and virtualization tools such as Docker and 
    Kubernetes. While his primary focus is on Layer 2 (L2) and Layer 3 (L3), he also assists his team 
    in the Customer Demo Lab.
    
    Salman completed his undergraduate studies in Computer Science at Towson University, where he was a 
    member of both the Software Engineering Club and the Cybersecurity Club.
    
    In his free time, Salman enjoys creating IDM music under the moniker Apsyllum (pronounced "asylum"). 
    He plays bass and keyboards and co-produces tracks in a band called FEVERDREAM.
    
    Salman is committed to continuous growth, and he is always exploring new technologies and enhancing 
    his skills.
    `;
  }

  function tellJoke() {
    const randomIndex = Math.floor(Math.random() * jokes.length);
    return jokes[randomIndex];
  }

  const fileContents = {
    "projects.txt": `
  1: Pledge       -     Fintech Web App            ~Live~
  2: Nome         -     Metronome App (Android)    ~Live~
  3: Buckets      -     Budgetting Web App         ~Upcoming~
  4: Midas        -     Music Player Web App       ~Upcoming~
  `,
    "cats-i-like.txt": `
     All of them.
  `,
    "upcoming-features.txt": `
  1. Easter eggs
  2. New projects
  3. New music
  `,
    "salman.txt": `
  Hi all! 👋 My name is Salman Zaheer. I am a Network Solutions Engineer, Software Developer, 
  UI/UX designer & a HUGE tech enthusiast. I enjoy building solutions with technology and finding
  creative ways of problem solving.

  I am currently employed at Ciena as a Solutions Engineer. I handle vBNG technology solutions for
  clients and work routinely with VMs and Virtualization tools such as Docker and Kubernetes. My 
  focus is mainly L2 & L3 but I try to provide assistance to the rest of our team in the Customer
  Demo Lab.

  I did my undergraduate from Towson University 🏫 in Computer Science. I was a member of Software
  Engineering Club as well as the Cybersecurity Club.

  In my free time I enjoy making IDM music. I go by the moniker Apsyllum (pronounced asylum) and I 
  play in a band 🎶, we go by FEVERDREAM! I play the bass and keyboards and co-produce the tracks.

  I believe in continous growth so you will always find me reading up on or tinkering with new
  technologies. 
  `,
  };

  function handleCommand(command) {
    let newOutput = "";
    const [cmd, arg] = command.split(" ");

    switch (cmd) {
      case "help":
        newOutput += printHelp();
        break;
      case "ls":
        newOutput += printLs();
        break;
      case "joke":
        newOutput += "\n" + tellJoke();
        break;
      case "cat":
        if (arg && fileContents[arg]) {
          newOutput += "\n" + fileContents[arg];
        } else {
          newOutput +=
            "\nFile not found or invalid command. Use 'cat [filename]'";
        }
        break;
      case "whois":
        newOutput += printWhois();
        break;
      case "github":
        window.open("https://github.com/salmanzaheer", "_blank");
        return; // No need to update output
      case "linkedin":
        window.open("https://linkedin.com/in/slmnzaheer", "_blank");
        return;
      case "reboot":
        window.location.reload();
        return;
      default:
        newOutput += printInvalid();
    }
    setOutput((prevOutput) => prevOutput + "\n\n$ " + command + newOutput);
  }

  return (
    <Box
      height="70vh"
      width="100%"
      bg="brand.100"
      padding="1rem"
      boxSizing="border-box"
    >
      {/* TERMINAL */}
      <Container
        className="shell"
        maxW="6xl"
        borderRadius="xl"
        height="60vh"
        boxShadow={isActiveTerminal ? "dark-lg" : "none"}
        transition="box-shadow 0.3s ease"
        bg="#101010"
        onClick={handleClickInside}
        fontFamily="Hack"
        overflowY="auto"
        ref={containerRef}
      >
        <Box color="#fed" whiteSpace="pre" fontFamily="monospace">
          {`                                                                                                                       
███████╗ █████╗ ██╗     ███╗   ███╗ █████╗ ███╗   ██╗    ███████╗ █████╗ ██╗  ██╗███████╗███████╗██████╗ 
██╔════╝██╔══██╗██║     ████╗ ████║██╔══██╗████╗  ██║    ╚══███╔╝██╔══██╗██║  ██║██╔════╝██╔════╝██╔══██╗
███████╗███████║██║     ██╔████╔██║███████║██╔██╗ ██║      ███╔╝ ███████║███████║█████╗  █████╗  ██████╔╝
╚════██║██╔══██║██║     ██║╚██╔╝██║██╔══██║██║╚██╗██║     ███╔╝  ██╔══██║██╔══██║██╔══╝  ██╔══╝  ██╔══██╗
███████║██║  ██║███████╗██║ ╚═╝ ██║██║  ██║██║ ╚████║    ███████╗██║  ██║██║  ██║███████╗███████╗██║  ██║
╚══════╝╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝    ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═╝ 
 
 Type "help" to see list of available commands.
  `}
        </Box>
        <Container
          className="terminal"
          textColor="#f0d"
          fontSize="sm"
          padding="0"
          margin="0"
          maxW="4xl"
          mb={4}
          whiteSpace="pre"
        >
          {output}
          <div ref={bottomRef} />
        </Container>
        <Text as="samp" fontFamily="Hack" textColor="red.500" fontSize="sm">
          salman@workstation:
          <Text as="span" fontFamily="Hack" textColor="red.400" fontSize="sm">
            ~/salman/information/
          </Text>
        </Text>
        <InputGroup maxW="4xl">
          <Text as="span" fontFamily="Hack" color="#0f0" fontSize="lg">
            <pre>$ </pre>
          </Text>
          <Input
            color="#0f0"
            ref={inputRef}
            fontSize="sm"
            backgroundColor="transparent"
            variant="unstyled"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCommand(input);
                setInput("");
              }
            }}
          />
        </InputGroup>
      </Container>
    </Box>
  );
};

export default About;
