import {
  Box,
  Heading,
  Text,
  Flex,
  Image,
  Divider,
  Link,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import heroImg from "../media/pexels-ryan-miguel-capili-1710923-3993249.jpg";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import SplitType from "split-type";

gsap.registerPlugin(useGSAP);

const Hero = () => {
  useGSAP(() => {
    const myHeading = new SplitType("#split");

    const handleMouseEnter = (char) => {
      gsap.to(char, {
        scaleX: 1.2,
        scaleY: 0.9,
        duration: 0.1,
        color: "#140729",
        ease: "power1.out",
      });
    };

    const handleMouseLeave = (char) => {
      gsap.to(char, {
        scaleX: 1,
        scaleY: 1,
        duration: 0.1,
        color: "#B9010E",
        ease: "power1.out",
      });
    };

    myHeading.chars.forEach((char) => {
      char.addEventListener("mouseenter", () => handleMouseEnter(char));
      char.addEventListener("mouseleave", () => handleMouseLeave(char));
    });

    // Clean up event listeners on unmount
    return () => {
      myHeading.chars.forEach((char) => {
        char.removeEventListener("mouseenter", () => handleMouseEnter(char));
        char.removeEventListener("mouseleave", () => handleMouseLeave(char));
      });
    };
  });

  return (
    <Box
      bg="brand.100"
      height="150vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p={{ base: 5, md: 10 }}
    >
      <Flex direction="column" align="center" width="80%">
        <Heading id="split" fontSize="9xl" color="brand.200" mb={4}>
          Salman Zaheer
        </Heading>

        <Flex
          direction={{ base: "column", md: "row" }}
          align="center"
          justify="center"
          width="100%"
        >
          <Box
            width={{ base: "100%", md: "50%" }}
            display="flex"
            justifyContent="center"
            mb={{ base: 10, md: 0 }}
          >
            <Image
              boxSize={{ base: "300px", md: "550px" }}
              src={heroImg}
              alt="Hero Image courtesy cottonbro"
              fallbackSrc="https://via.placeholder.com/150"
              objectFit="contain"
            />
          </Box>

          <Box
            pl={{ base: 0, md: 10 }}
            width={{ base: "100%", md: "50%" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Text
              as="b"
              fontFamily="Syncopate"
              color="brand.400"
              fontSize="2xl"
            >
              MD-BASED DEVELOPER & ENGINEER
            </Text>
            <Text
              color="brand.400"
              fontSize={{ base: "lg", md: "xl" }}
              mt={20}
              mb={10}
            >
              Salman Zaheer is a MD based developer, designer, engineer and
              musician. His keen eye for beautiful design and sharp attention to
              detail punctuates his signature style. Salman believes in finding
              harmony in UX and UI design with modern functionality to provide
              an experience unlike any other to the end-user.
            </Text>
            <Link
              href="/about"
              color="brand.400"
              fontSize="xl"
              _hover={{ textDecoration: "none", color: "brand.200" }}
            >
              <ArrowForwardIcon />
              {"  "}
              <Text as="u">About Salman</Text>
            </Link>
          </Box>
        </Flex>
      </Flex>
      <Divider borderColor="brand.200" borderWidth="2px" mt={40} width="80%" />
    </Box>
  );
};

export default Hero;
