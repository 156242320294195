import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import Homepage from "./components/Homepage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Resume from "./components/Resume";
import { ReactLenis, useLenis } from "lenis/react";
import About from "./components/About";
import Midas from "./components/Midas";

function App() {
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  });

  return (
    <BrowserRouter>
      <ReactLenis root options={(t) => -(Math.cos(Math.PI * t) - 1) / 2}>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/about" element={<About />} />
          <Route path="/midas" element={<Midas />} />
        </Routes>
        <Footer />
      </ReactLenis>
    </BrowserRouter>
  );
}

export default App;
