import { Box, Heading, Flex, Image } from "@chakra-ui/react";
import img1 from "../media/pexels-nubikini-386010.jpg";
import img2 from "../media/pexels-orlovamaria-4915825.jpg";
import img3 from "../media/pexels-mikhail-nilov-7828176.jpg";

const Overview = () => {
  return (
    <Box
      bg="brand.100"
      height="150vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      p={10}
    >
      <Flex direction="row" align="center" width="60%">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="50%"
        >
          <Heading fontSize={{ base: "6xl" }} color="brand.400">
            Challenging Ideals
          </Heading>
          <Image boxSize="400px" src={img3} objectFit="cover" />
          <Heading fontSize={{ base: "6xl" }} color="brand.400">
            Always Growing
          </Heading>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="50%"
        >
          <Image boxSize="400px" src={img2} objectFit="cover" />
          <Heading fontSize={{ base: "6xl" }} color="brand.400">
            Continuous Learning
          </Heading>
          <Image boxSize="400px" src={img1} objectFit="cover" />
        </Box>
      </Flex>
    </Box>
  );
};

export default Overview;
