import React from "react";
import Hero from "./Hero";
import Overview from "./Overview";

import { Box } from "@chakra-ui/react";

function Homepage() {
  return (
    <Box>
      <Hero />
      <Overview />
    </Box>
  );
}

export default Homepage;
