import { Box, Grid, GridItem } from "@chakra-ui/react";
import React from "react";

function Midas() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        height="60%"
        width="60%"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Grid
          templateAreas={`"files search"
        "files discover"
        "files player"`}
          gridTemplateRows={"10% 1fr 18%"}
          gridTemplateColumns={"15% 1fr"}
          h="100%"
          gap="0.5"
          color="blackAlpha.700"
          fontWeight="bold"
        >
          <GridItem pl="2" bg="orange.300" area={"search"}>
            search bar
          </GridItem>
          <GridItem pl="2" bg="pink.300" area={"files"}>
            files
          </GridItem>
          <GridItem pl="2" bg="green.300" area={"discover"}>
            discover
          </GridItem>
          <GridItem pl="2" bg="blue.300" area={"player"}>
            player
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}

export default Midas;
